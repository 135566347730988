import React, { useRef, useState, useEffect } from 'react';

const Slider = ({ children, gap }) => {
  const containerRef = useRef(null);
  const contentRef = useRef(null);

  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(null);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [shouldHideButtons, setShouldHideButtons] = useState(false);

  // useEffect(() => {
  //   const containerWidth = containerRef.current.offsetWidth;
  //   const contentWidth = contentRef.current.offsetWidth;
  //   setShouldHideButtons(contentWidth <= containerWidth);
  // }, [children]);

  const handleMouseDown = (e) => {
    e.preventDefault();
    setIsDragging(true);
    setStartX(e.clientX);
    setScrollLeft(containerRef.current.scrollLeft);
    containerRef.current.style.scrollBehavior = 'auto';
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    containerRef.current.style.scrollBehavior = 'smooth';
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const deltaX = e.clientX - startX;
    containerRef.current.scrollLeft = scrollLeft - deltaX;
  };

  const handleScrollLeft = () => {
    containerRef.current.scrollLeft -= 350; // Adjust the scroll amount as needed
  };

  const handleScrollRight = () => {
    containerRef.current.scrollLeft += 350; // Adjust the scroll amount as needed
  };

  return (
    <div className='relative w-full overflow-x-hidden md:px-10'>
      {!shouldHideButtons && (
        <button
          onClick={handleScrollLeft}
          className='absolute left-0 top-1/2 z-10 flex h-8 w-8 -translate-y-1/2 transform items-center justify-center rounded-full border border-gray-300 bg-white text-xl font-medium text-gray-600 transition duration-300 hover:bg-gray-100 focus:outline-none'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth={1.5}
            stroke='currentColor'
            className='h-5 w-5'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18'
            />
          </svg>
        </button>
      )}
      {!shouldHideButtons && (
        <button
          onClick={handleScrollRight}
          className='absolute right-0 top-1/2 z-10 flex h-8 w-8 -translate-y-1/2 transform items-center justify-center rounded-full border border-gray-300 bg-white text-xl font-medium text-gray-600 transition duration-300 hover:bg-gray-100 focus:outline-none'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth={1.5}
            stroke='currentColor'
            className='h-5 w-5'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3'
            />
          </svg>
        </button>
      )}
      <div
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
        ref={containerRef}
        className='whitespace-no-wrap no-scrollbar relative flex h-full min-h-fit w-full flex-row overflow-x-auto rounded-xl p-2'
      >
        <div
          className={`flex flex-row ${gap ? gap : 'gap-5'}`}
          ref={contentRef}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Slider;
