import React from 'react';
import Image from 'next/image';
const UtilityHeading = ({ data, handleChange, id }) => {
  const { _id, name, image } = data;
  return (
    <div
      onMouseEnter={() => handleChange(name)}
      onClick={() => handleChange(name)}
      className='flex flex-col'
    >
      <div
        className={`h-28 shrink-0 cursor-pointer overflow-hidden rounded-lg shadow md:h-32 2xl:h-52 ${id === name ? 'border-2 border-solid border-blue-500' : 'grayscale filter'}`}
      >
        <Image
          src={image}
          alt={name}
          width={400}
          height={400}
          className='h-full w-full object-cover'
        />
      </div>
      <h2 className='py-2 text-center text-sm font-bold md:text-base lg:text-xl'>
        <span className='text-[#FFAE5D]'>{name}</span>
      </h2>
    </div>
  );
};

export default UtilityHeading;
