'use client';
import React from 'react';
import { useInView } from 'react-intersection-observer';
import Link from 'next/link';
import useCustomSWR from '@/hook/useCustomSWR';
const Wfh = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '500px 0px',
    threshold: 0,
  });
  const { data, error } = useCustomSWR(
    `/v1/categories/get-tags-category?tag=wfh&page=1&limit=10`,
    inView
  );

  const { data: banner } = useCustomSWR(
    `/v1/cms/get-home-page-banner?title=wfh-page-banner`,
    inView
  );
  const image = banner?.response?.slider_images[0]?.img_url;
  if (!inView) return <div ref={ref}></div>;
  return (
    <div ref={ref} className='flex flex-col'>
      <div className='mb-10'>
        <p className='py-1 text-center text-xl sm:text-2xl md:text-3xl font-bold uppercase'>
          Boost Your Productivity
        </p>
        <p className='text-center text-xs md:text-sm font-normal text-opacity-50'>
          {' '}
          Explore Our Collection That Makes Remote Work Smoother
        </p>
      </div>
      <div className='w-full'>
        <Link href='/collections/wfh'>
          <img
            src={image}
            loading='lazy'
            className='w-full'
            alt='21genx wfh banner'
          />
        </Link>
      </div>
      <div className='my-5 flex flex-row flex-wrap justify-center gap-2 md:gap-5'>
        {data?.results?.map((category, index) => {
          return (
            <Link
              key={category._id}
              href={category.childCategoriesPath}
              className={`relative shrink-0 basis-[18%] cursor-pointer overflow-hidden rounded-xl`}
            >
              <div>
                <img
                  loading='lazy'
                  src={category.category_img}
                  alt={category.category_name}
                  className='h-16 w-full rounded-md object-cover sm:h-24 md:h-28 lg:h-40'
                />
              </div>

              <div className='absolute inset-x-0 bottom-0 hidden bg-black bg-opacity-10 backdrop-blur sm:block'>
                <h3 className='text-center text-[10px] font-semibold text-white sm:text-xs lg:text-base lg:leading-10'>
                  {category.category_name}
                </h3>
              </div>
            </Link>
          );
        })}
      </div>
      <a
        href={'/collections/wfh'}
        className='flex items-center justify-center text-lg uppercase underline'
      >
        Discover More {` -->`}
      </a>
    </div>
  );
};

export default Wfh;
