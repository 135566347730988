import useSWR from 'swr';
import BASE_URL from '@/components/constants';
import getToken from './getToken';
const fetcher = async (arg, token) => {
  const [url, inView] = arg;
  if (!inView) return;
  const apiUrl = BASE_URL + url;

  const headers = getToken() ? { Authorization: `Bearer ${getToken()}` } : {};

  const response = await fetch(apiUrl, { headers });
  const data = await response.json();
  return data;
};

const useCustomSWR = (url, inView) => {
  const { data, error, mutate } = useSWR([url, inView], fetcher, {
    revalidateOnMount: true,
    refreshWhenHidden: true,
    refreshWhenOffline: true,
    revalidateOnFocus: false,
    dedupingInterval: 3600 * 1000,
    refreshInterval: 3600 * 1000,
  });

  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

export default useCustomSWR;
