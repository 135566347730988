'use client';
import React from 'react';
import { cn } from '@/utils/cn';

const DisplayHeadingImage = ({ data, handleChange, id, className }) => {
  const { _id, name, image } = data;

  return (
    <div
      className={cn(
        'relative h-16 w-16 shrink-0 transform cursor-pointer overflow-hidden rounded-full transition-transform duration-300 ease-in-out hover:scale-110 hover:opacity-90 hover:shadow-lg xl:h-20 xl:w-20',
        id === _id || id === name ? 'ring-2 ring-gray-400' : 'grayscale filter',
        className
      )}
      onMouseOver={() => handleChange(_id, name)}
    >
      <div className='h-full w-full'>
        <img
          loading='lazy'
          width={100}
          height={100}
          src={image}
          alt={name}
          className='h-full w-full object-cover'
        />
      </div>
    </div>
  );
};

export default DisplayHeadingImage;
