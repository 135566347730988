'use client';
import React, { useState, useMemo, useEffect, use } from 'react';
import Link from 'next/link';
import '../css/home.css';
import DisplayHeadingImage from '../DisplayHeadingImage';
import ProductCard from '../ProductCard';
import RedirectLink from '../RedirectLink';
import useCustomSWR from '@/hook/useCustomSWR';
import { useInView } from 'react-intersection-observer';
import { Skeleton } from '@mui/material';
import { WobbleCard } from '@/components/ui/wobble-card';
const categoryImg = [
  {
    id: '64b4d04e959d127070891af5',
    img: '/3C.jpg',
  },
  {
    id: '657706484854651840a9f915',
    img: 'office.jpg',
  },
  {
    id: '659fb50c3f073aa9c092ba64',
    img: '/home-kitchen.jpg',
  },
  {
    id: '65cf5476d2992b052a326a33',
    img: '/kids.jpg',
  },
  {
    id: '65d31adcd2992b052a328b1b',
    img: '/lIfestyle.jpg',
  },
];
const CategoriesList = ({ listData }) => {
  const [isVisible, setIsVisible] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '500px 0px',
    threshold: 0,
  });

  useEffect(() => {
    if (!inView) return;
    setIsVisible(true);
  }, [inView]);

  const [categoryId, setCategoryId] = useState();
  useEffect(() => {
    if (!listData) return;
    if (listData[0]?._id) {
      setCategoryId(listData[0]?._id);
    }
  }, [listData]);
  const [selectedImg, setSelectedImg] = useState();
  const { data, isLoading, isError, revalidate } = useCustomSWR(
    `/v1/products/get-all-products?filter[parent_category_id][$eq]=${categoryId}&page=1&limit=8&random=yes`,
    inView
  );
  const productList = data?.productList;
  useMemo(() => {
    if (!categoryId) return;
    const img = categoryImg.find((img) => img.id === categoryId);
    setSelectedImg(img.img);
  }, [categoryId]);

  const handleCategory = (id) => {
    setCategoryId(id);
  };
  if (!isVisible) return <div ref={ref}></div>;
  return (
    <div ref={ref} className='flex flex-col'>
      <div className='mb-10'>
        <p className='py-1 text-center text-xl sm:text-2xl md:text-3xl font-bold uppercase'>
          <Link href={'/category'}>Find What You’re Looking For</Link>
        </p>
        <p className='text-center text-xs md:text-sm font-normal text-opacity-50'>
          {' '}
          Browse by categories and surprise your senses
        </p>
      </div>
      <div className='home-heading w-full sm:justify-center'>
        {listData &&
          listData?.map((category) => (

            <DisplayHeadingImage
              key={category._id}
              className='shrink-0 w-36 md:48  xl:w-52 h-fit xl:h-fit hover:scale-100 rounded-lg'
              data={category}
              handleChange={handleCategory}
              id={categoryId}
            />

          ))}
      </div>
      <div className='mt-2 grid grid-cols-2 gap-2 sm:grid-cols-3 md:mt-5 md:grid-cols-4 md:gap-5 lg:grid-cols-5'>
        {isLoading
          ? Array.from({ length: 10 }, (_, index) => (
            <div
              key={index}
              className='h-52 w-full overflow-hidden rounded-lg md:h-60'
            >
              <Skeleton
                animation='wave'
                variant='rectangular'
                width='100%'
                height='100%'
              />
            </div>
          ))
          : null}
        {productList && productList.length !== 0 && (
          <div className='h-52 w-full overflow-hidden md:h-60'>
            <img
              src={selectedImg}
              alt='category-image'
              className='h-full w-full object-contain'
            />
          </div>
        )}

        {productList &&
          productList.length !== 0 &&
          productList.map((product, index) => {
            return (
              <ProductCard
                key={product._id}
                product={product}
                style={'w-full h-52 md:h-60 '}
                iconStyle={`w-5 h-5`}
                textSize={`text-xs `}
                quickViewWidth={24}
                quickViewHeight={24}
              />
            );
          })}
        {productList && productList.length !== 0 && (
          <div className='h-52 w-full overflow-hidden md:h-60'>
            <img
              src={'/common-category.jpg'}
              alt='category-image-2'
              className='h-full w-full object-contain'
            />
          </div>
        )}
      </div>
      {productList?.length !== 0 && (
        <RedirectLink
          name='category'
          id={categoryId}
          section={'Category'}
          listData={listData}
        />
      )}
    </div>
  );
};

export default CategoriesList;
