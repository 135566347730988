'use client';
import React, { useState, useEffect, useMemo } from 'react';
import Link from 'next/link';
import useCustomSWR from '@/hook/useCustomSWR';
import DisplayHeadingImage from '../DisplayHeadingImage';
import ProductCard from '../ProductCard';
import RedirectLink from '../RedirectLink';
import Slider from '@/components/Slider';
import { useInView } from 'react-intersection-observer';
import { Skeleton } from '@mui/material';
const BrandsList = ({ listData }) => {
  const [isVisible, setIsVisible] = useState(false);
  const { ref, inView, entry } = useInView({
    /* Optional options */
    triggerOnce: true,
    rootMargin: '500px 0px',
    threshold: 0,
  });
  useEffect(() => {
    if (!inView) return;
    setIsVisible(true);
  }, [inView]);
  const [brandId, setBrandId] = useState();
  useEffect(() => {
    if (!listData) return;
    if (listData[0]?._id) {
      setBrandId(listData[0]?._id);
    }
  }, [listData]);
  const { data, isLoading, isError, revalidate } = useCustomSWR(
    `/v1/products/get-all-products?filter[brand_id][$eq]=${brandId}&random=yes&page=1&limit=5`,
    inView
  );
  const productList = data?.productList;
  const handleBrand = (id) => {
    setBrandId(id);
  };

  const { aboutBanner, selectedName, slug } = useMemo(() => {
    if (!listData && !brandId) return {};
    const banner = listData?.find((item) => item._id === brandId);
    return { aboutBanner: banner?.about_banner, selectedName: banner?.name, slug: banner?.slug };
  }, [listData, brandId]);

  if (!isVisible) return <div ref={ref}></div>;
  return (
    <div ref={ref} className='flex flex-col'>
      <div className='md:mb-5'>
        <Link
          href={'/brand'}
          className='flex justify-center py-1 text-center text-xl sm:text-2xl md:text-3xl font-bold uppercase'
        >
          Top Innovative Brands
        </Link>
        <p className='text-center text-xs md:text-sm font-normal text-opacity-50'>
          {' '}
          Step into a future of empowering lifestyles
        </p>
      </div>
      <div className='home-heading w-full'>
        <Slider>
          {!listData &&
            Array.from({ length: 5 }, (_, index) => (
              <div className='h-16 w-16 xl:h-20 xl:w-20'>
                <Skeleton
                  animation='wave'
                  variant='circular'
                  width='100%'
                  height='100%'
                />
              </div>
            ))}
          {listData?.map((brand) => (
            <DisplayHeadingImage
              key={brand._id}
              data={brand}
              handleChange={handleBrand}
              id={brandId}
            />
          ))}
        </Slider>
      </div>
      <div className='w-full'>
        {isLoading && !productList && (
          <div className='home-product-section'>
            {Array.from({ length: 6 }, (_, index) => (
              <div className='h-52 w-full overflow-hidden rounded-lg md:h-96 2xl:h-[30rem]'>
                <Skeleton
                  animation='wave'
                  variant='rectangular'
                  width='100%'
                  height='100%'
                />
              </div>
            ))}
          </div>
        )}

        {productList?.length !== 0 && (
          <div className='home-product-section'>
            <div className='h-52 overflow-hidden md:h-96 2xl:h-[30rem]'>
              <img
                src={aboutBanner}
                alt='Banner'
                className='aspect-square h-full w-full object-contain'
              />
            </div>
            {productList?.map((product, index) => (
              <ProductCard
                key={product._id}
                product={product}
                style={'w-full h-52 md:h-96 2xl:h-[30rem]'}
                iconStyle={`w-5 h - 5 md: w - 7 md: h - 7`}
                textSize={`text - xs md: text - sm`}
                quickViewWidth={24}
                quickViewHeight={24}
              />
            ))}
          </div>
        )}
      </div>

      <div className='my-5 flex flex-col items-center justify-center py-2'>
        {
          <Link
            href={`/brand/${slug}`}
            className='flex w-fit items-center gap-2 rounded px-4 text-center text-lg font-normal text-gray-700'
          >
            {` Explore ${selectedName} --> `}
          </Link>
        }

        <Link
          href={`/brand`}
          className='flex w-fit items-center gap-2 rounded px-4 text-center text-lg font-normal text-gray-700'
        >
          {`Explore all brand -->`}
        </Link>
      </div>
    </div>
  );
};

export default React.memo(BrandsList);
