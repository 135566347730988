'use client'
import React from 'react';
import Link from 'next/link';
import { Swiper, SwiperSlide } from 'swiper/react';
import useCustomSWR from '@/hook/useCustomSWR';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Skeleton } from '@mui/material';
const HeroBanner = () => {
    const { data, isLoading, isError, revalidate } = useCustomSWR(
        `/v1/cms/get-home-page-banner?title=hero-section-banner`,
        true
    );
    console.log('data', data);
    function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            // Pick a random index from 0 to i
            const randomIndex = Math.floor(Math.random() * (i + 1));

            // Swap the elements at i and randomIndex
            [array[i], array[randomIndex]] = [array[randomIndex], array[i]];
        }
        return array;
    }
    if (!data?.response?.slider_images) {
        return (
            <Swiper
                modules={[Scrollbar, A11y, Autoplay, Pagination]} // Include Autoplay module here
                spaceBetween={50}
                slidesPerView={1}
                pagination={{ clickable: true }}
                // scrollbar={{ draggable: true }}
                autoplay={{ // Add autoplay configuration
                    delay: 3000, // Delay in milliseconds (3 seconds in this case)
                    disableOnInteraction: false, // Keep autoplay active after user interactions
                }}
                // onSlideChange={() => console.log('slide change')}
                onSwiper={(swiper) => console.log(swiper)}
            >
                {[1, 2, 3].map(d => (
                    <SwiperSlide>
                        <div className='w-full h-40 sm:h-52 md:h-96 lg:h-[85vh]'>
                            <Skeleton
                                key={d}
                                animation='wave'
                                variant='rectangular'
                                width='100%'
                                height='100%'
                            />
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        );
    }

    const banner = shuffleArray(data?.response?.slider_images);
    return (
        <Swiper
            modules={[Scrollbar, A11y, Autoplay, Pagination]} // Include Autoplay module here
            spaceBetween={50}
            slidesPerView={1}
            pagination={{ clickable: true }}
            // scrollbar={{ draggable: true }}
            autoplay={{ // Add autoplay configuration
                delay: 3000, // Delay in milliseconds (3 seconds in this case)
                disableOnInteraction: false, // Keep autoplay active after user interactions
            }}
            // onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
        >
            {
                banner?.map(brand => (
                    <SwiperSlide>
                        <Link href={`/brand/${brand.brand_id}`} target='_blank'>
                            <img src={brand?.img_url} alt="Hero Banner 1" />
                        </Link>
                    </SwiperSlide>
                ))
            }
        </Swiper>
    );
};

export default HeroBanner;
