'use client'
import React from 'react';

const AllBannerSection = () => {
    const banners = [
        {
            image: '/hero1.jpg',
            link: "#category",
        },
        {
            image: '/hero2.jpg',
            link: "#brand"
        },
        {
            image: '/hero3.jpg',
            link: "#award"
        },
        {
            image: '/hero4.jpg',
            link: "#utility"
        },
        {
            image: '/hero5.jpg',
            link: "#country"
        }
    ];

    const handleClick = (e, link) => {
        e.preventDefault();
        const targetElement = document.querySelector(link);
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <section className='p-5'>
            <h2 className='text-left text-xl sm:text-2xl md:text-3xl md:text-center font-medium'>
                <span className='font-bold'>Exclusive </span>
                Products. <span className='font-bold'>Elevate</span>
                <span className='ml-2'>your</span> <span className='font-bold'>Experience</span> with our
                <span className='font-bold'> Premium Curated Collection.</span>
            </h2>
            <div className='flex flex-row items-center overflow-x-scroll no-scrollbar md:grid grid-cols-5 gap-3 md:gap-4 lg:gap-5 mt-5'>
                {banners.map((i, index) => (
                    <a className='basis-[30%]  shrink-0 md:w-full'
                        key={index} href={i.link} onClick={(e) => handleClick(e, i.link)}>
                        <img className='rounded-xl overflow-hidden border shadow' src={i.image} alt={`Banner ${index + 1}`} />
                    </a>
                ))}
            </div>
        </section>
    );
};

export default AllBannerSection;
