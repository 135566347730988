'use client';
import React, { useState, useEffect } from 'react';
import '../css/home.css';
import UtilityHeading from './UtilityHeading';
import BASE_URL from '@/components/constants';
import ProductCard from '../ProductCard';
import RedirectLink from '../RedirectLink';
// Import Swiper React components
import { Virtual, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { useInView } from 'react-intersection-observer';
import useCustomSWR from '@/hook/useCustomSWR';
import { Skeleton } from '@mui/material';
const UtilitiesList2 = ({ listData }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [utilityTag2, setUtilityTag2] = useState();
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '500px 0px',
    threshold: 0,
  });
  useEffect(() => {
    if (!inView) return;
    setIsVisible(true);
  }, [inView]);

  const { data, isLoading, isError, revalidate } = useCustomSWR(
    `/v1/products/get-all-products?filter[utility_tags][$eq]=${utilityTag2}&page=1&limit=12&random=yes`,
    inView
  );
  const productList = data?.productList;
  const handleUtility2 = (tag) => {
    setUtilityTag2(tag.toString());
  };
  if (!isVisible) return <div ref={ref}></div>;
  return (
    <div ref={ref} className='flex flex-col'>
      <div className='no-scrollbar grid w-full grid-cols-2 items-center justify-center gap-2 overflow-x-scroll py-2 md:grid-cols-4 md:gap-5'>
        {listData?.slice(4, 8).map((utility, index) => (
          <UtilityHeading
            key={utility._id + index}
            data={utility}
            handleChange={handleUtility2}
            id={utilityTag2}
          />
        ))}
      </div>
      <div className='w-full'>
        <Swiper
          modules={[Virtual, Navigation]}
          slidesPerView={4}
          // centeredSlides={true}
          spaceBetween={20}
          navigation={true}
          virtual
          breakpoints={{
            // when window width is >= 320px
            320: {
              slidesPerView: 1,
            },
            480: {
              slidesPerView: 2,
            },
            // when window width is >= 640px
            640: {
              slidesPerView: 3,
            },
            // when window width is >= 768px
            768: {
              slidesPerView: 4,
            },
            1024: {
              slidesPerView: 5,
            },
          }}
        >
          {isLoading &&
            Array.from({ length: 6 }, (_, index) => (
              <SwiperSlide key={index} virtualIndex={index}>
                <div className='h-80 w-full overflow-hidden rounded-lg'>
                  <Skeleton
                    animation='wave'
                    variant='rectangular'
                    width='100%'
                    height='100%'
                  />
                </div>
              </SwiperSlide>
            ))}
          {productList?.map((product, index) => (
            <SwiperSlide key={product._id + index} virtualIndex={index}>
              <ProductCard
                key={product._id}
                isSwiper={true}
                product={product}
                style={'w-full h-80 '}
                iconStyle={`w-7 h-7`}
                textSize={`text-sm`}
                quickViewWidth={24}
                quickViewHeight={24}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      {productList?.length !== 0 && (
        <RedirectLink
          name='utility'
          id={utilityTag2}
          section={'Utility'}
          listData={listData}
        />
      )}
    </div>
  );
};

export default UtilitiesList2;
