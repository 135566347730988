'use client';
import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import Image from 'next/image';
import useCustomSWR from '@/hook/useCustomSWR';
import { useInView } from 'react-intersection-observer';
const Banner = ({ title, bannerName }) => {
  const [isVisible, setIsVisible] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '500px 0px',
    threshold: 0,
  });
  useEffect(() => {
    if (!inView) return;
    setIsVisible(true);
  }, [inView]);
  const { data, isLoading, isError, revalidate } = useCustomSWR(
    `/v1/cms/get-home-page-banner?title=${title}`,
    inView
  );



  if (!isVisible) return <div ref={ref}></div>;
  return (
    <div ref={ref} className='flex w-full px-1'>
      <Swiper
        spaceBetween={20}
        centeredSlides={true}
        navigation
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        loop={true}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination, Navigation]}
      >
        {data?.response?.slider_images?.map((imageUrl, index) => (
          <SwiperSlide key={index} className='relative'>
            {
              bannerName ? (
                <Link
                  href={`${bannerName}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img
                    src={imageUrl.img_url}
                    className='h-full w-full object-contain md:object-fill'
                    loading='lazy'
                    alt={`Slide ${index + 1}`}
                  />
                </Link>
              ) : (
                <img
                  src={imageUrl.img_url}
                  className='h-full w-full object-contain md:object-fill'
                  loading='lazy'
                  alt={`Slide ${index + 1}`}
                />
              )
            }

          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Banner;
